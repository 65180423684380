.ant-modal
  top: 30px

  .strong
    font-weight: 500

  .gdpr-version
    margin: 0 0 2em 0
    text-align: center

  ol
    counter-reset: item
    list-style: none
    padding-inline-start: 1.5em

    li
      counter-increment: item
      position: relative

      &::before
        content: counters(item, ".") ". "
        position: absolute
        top: 2px
        left: -1.5em

      ol
        padding-inline-start: 1.5em

        > li::before
          content: counters(item, ".") ". "
          top: 0

        li
          ol
            padding-inline-start: 2em

            li::before
              content: counter(item, lower-alpha) ") " !important
              left: -0.5em
              top: 0

      p
        padding-left: .8em

      .fs110
        font-size: 1.1em
        padding-left: 0

.gdpr-modal
  .ant-modal-header
    margin: 2em 0 .3em

    .ant-modal-title
      font-size: 1.5em
      text-transform: uppercase
      text-align: center

.cancel-reservation-modal
  .ant-modal-content
    padding-left: 10%
    padding-right: 10%

    .ant-modal-header
      margin: 1.5em 0
      text-align: center

      .ant-modal-title
        color: $text-secondary-color
        font-weight: $text-font-weight-strong
        font-size: 1.8em

    .ant-modal-body
      .ant-radio-group
        width: 100%

        .ant-radio-wrapper
          color: $text-primary-color
          font-weight: $text-font-weight-strong
          height: 2em !important

          .ant-radio-checked
            .ant-radio-inner
              background-color: $primary-color

              &:after
                background-color: $text-secondary-color
                transform: scale(0.65) !important

            &:after, &:hover
              border-color: rgba(0, 75, 108, 0.48) !important
              border: 2px

          .ant-radio-inner
            border-color: rgba(0, 75, 108, 0.48)

          span:last-child
            font-size: 1.4em

      .textarea-container
        textarea
          border: 1px solid rgba(0, 0, 0, 0.18)
          color: $text-primary-color

          &:hover
            border-color: rgba(0, 0, 0, 0.18) !important

          &:focus
            border: 1px solid $text-secondary-color !important
            box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0 0 8px rgba(0, 0, 0, 0.2)

      .btn-container
        margin: 2em 0
        width: 100%
        justify-content: center

        @media #{$medium-and-down}
          flex-direction: column-reverse

        @media #{$small-and-down}
          padding: 0