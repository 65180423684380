html, body
  margin: 0 !important
  padding: 0 !important
  background-color: white
  font-family: 'Rubik', sans-serif
  -webkit-font-smoothing: antialiased

.loading-overlay-layer
  position: fixed
  top: 0
  left: 0
  display: flex
  align-items: center
  justify-content: center
  background: #fff
  transition: opacity .333s ease-in
  opacity: 1
  height: 100vh
  width: 100vw
  z-index: 99

  &.fade-out
    opacity: 0

main
  position: relative

  &:after
    content: ''
    position: absolute
    top: 100%
    left: 0
    right: 0
    height: 2.25rem
    background-color: inherit
    z-index: 1

main:not(.login-page):not(.male):not(.female)
  background: $background-primary-color

  h1
    color: $text-secondary-color
    font-size: 2.25em
    font-weight: $text-font-weight-strong
    margin: 0 0 2.625rem 0

main.page-with-title
  padding-bottom: 8.6em

  @media screen and (max-width: 600px)
    padding-bottom: 7.6em

.dib
  display: inline-block

.hidden
  display: none!important

label
  font-weight: normal

strong
  font-weight: $text-font-weight-strong

.no-padding
  padding: 0

.margin-auto
  margin: 0 auto !important

  @media #{$small-xs-and-down}
    margin: 0 !important

.col-image-moje-amber-pencil
  .ant-image
    padding-top: 158px
    width: 317.57px

    @media #{$large-and-down}
      padding-top: 30px


//  SPACER GENERATOR
$list-of-spaces: 0, 0.5rem, 1rem, 2rem, 3rem

=class-generator($name, $key, $value)
  .#{$name}
    #{$key}: $value!important

=spacer-generator($name, $key)
  @for $i from 0 to 5
    +class-generator(#{$name}-#{$i}, $key, nth($list-of-spaces,$i+1))

+spacer-generator('mt', 'margin-top')
+spacer-generator('mr', 'margin-right')
+spacer-generator('mb', 'margin-bottom')
+spacer-generator('ml', 'margin-left')
+spacer-generator('ma', 'margin')
+spacer-generator('mi', 'margin-inline')
+spacer-generator('mbl', 'margin-block')


+spacer-generator('pt', 'padding-top')
+spacer-generator('pr', 'padding-right')
+spacer-generator('pb', 'padding-bottom')
+spacer-generator('pl', 'padding-left')
+spacer-generator('pa', 'padding')
+spacer-generator('pi', 'padding-inline')
+spacer-generator('pbl', 'padding-block')
