$tag-background-color: #F9AB00

.rewards
  h1
    font-size: $h1-fontsize
    color: $text-secondary-color
    letter-spacing: 0
    text-align: center
    line-height: $line-height-h1
    font-weight: $text-font-weight-strong
    margin: 0 0 9px

    @media #{$medium-md-and-down}
      margin: 0 0 29px
      font-size: $h3-fontsize
      line-height: $line-height-h3

  h4
    font-size: $h5-fontsize
    color: $text-secondary-color
    letter-spacing: 0
    text-align: center
    line-height: $line-height-h4
    font-weight: $text-font-weight-normal
    margin: 0 0 30px

    @media #{$medium-md-and-down}
      margin: 30px 0 30px
      font-size: $h3-fontsize
      font-weight: $text-font-weight-strong

  .blue-circle
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    background: rgba(0,75,108,0.12)
    border-radius: 120px
    width: 240px
    height: 240px

    h2
      font-size: $h2-fontsize
      color: $text-secondary-color
      letter-spacing: 0
      line-height: $line-height-h2
      font-weight: $text-font-weight-strong
      margin: 13px 0 14px

      @media #{$medium-md-and-down}
        margin: 10px 0 11px

    p
      color: $text-secondary-color
      letter-spacing: 0
      text-align: center
      font-weight: $text-font-weight-normal
      margin: 0

      &:first-of-type
        font-size: $default-font-size
        line-height: $line-height-h5

      &:last-of-type
        font-size: $text-font-size
        line-height: $line-height-h6

    &.mobile
      width: 156px
      height: 156px


  .order
    background: $primary-color
    border-radius: 18px
    padding: 27px 13.5px
    text-align: center
    width: 156px
    height: 156px

    .ant-image
      width: 67.2px

    p
      font-size: $default-font-size
      color: $text-primary-color
      letter-spacing: 0
      text-align: center
      line-height: $line-height-default
      font-weight: $text-font-weight-strong

  .rewards-item-container
    display: flex

    .rewards-item
      background: $primary-color
      border-radius: 53px
      width: 105px
      height: 105px
      display: block

      &:hover
        border-color: $primary-color

      &.disabled
        opacity: 0.33
        pointer-events: none
        //background-color: red

      p
        font-size: 0.688rem
        color: $text-primary-color
        text-align: center
        line-height: calc($line-height-default - 1px)
        font-weight: $text-font-weight-strong
        margin: 0.3em 0

        span
          line-height: 1em
          vertical-align: middle

      .ant-image
        width: 50.5px

    @media #{$medium-md-and-down}
      justify-content: center

  .rewards-history-item-wrapper
    .advertising
      text-align: left

      p
        color: $text-secondary-color
        font-size: 0.688rem

    .ant-list
      .ant-list-items
        .ant-list-item
          background-color: inherit

          &:last-of-type
            border-radius: 0

    @media #{$medium-md-and-down}
      flex-direction: column-reverse

      .advertising
        text-align: right
        margin: 2em 0

        p
          max-width: 480px
          margin: 0 auto

      .ant-list
        margin: 0 auto

.rewards-item-modal
  .ant-modal-content
    text-align: center
    padding: 50px 18px

    .ant-modal-close
      width: $modal-close-width
      height: $modal-close-width

      &:hover
        background-color: inherit

    .ant-modal-title
      font-size: $h3-fontsize
      color: $text-secondary-color
      line-height: $line-height-h3
      font-weight: $text-font-weight-strong

    .ant-modal-body
      .ant-image
        width: 100%
        text-align: center
        margin: 45px 0

    form
      .btn-primary
        width: 210px
        margin: 18px 0
      p
        margin: 0

        .text-info
          font-size: $text-font-size


    .ant-modal-footer
      text-align: center


.rewards-history-title
  .ant-space-item:nth-child(1)
    position: absolute
    left: 14px

    a
      color: $text-primary-color
      text-decoration-line: underline
      font-weight: $text-font-weight-strong

      .anticon
        margin-right: 5px

    @media #{$large-and-down}
      top: 0

      a
        background-color: $primary-color
        width: 36px
        height: 36px
        border-radius: 2em
        padding: 10px
        display: inline-block

      .back-to-rewards
        display: none


.rewards-history-item
  text-align: left
  color: $text-secondary-color
  width: 100%

  .ant-space-item:last-child
    margin-left: auto

  .ant-image
    margin: 1em 2em 1em 1em
    padding: 14px
    background-color: white
    border-radius: 50%
    width: 96px
    height: 96px
    float: left
    display: flex
    justify-content: center



    .ant-image-img
      margin: auto

.points-history-overview
  h1
    margin: 0 0 .25em 0!important

    @media #{$medium-md-and-down}
      font-size: $h4-fontsize !important
      line-height: 1.8em !important

  h3
    color: $text-secondary-color
    font-weight: 400
    font-size: 1.5em

    .ant-tag
      background-color: $primary-color
      display: inline-flex
      align-items: center
      gap: 5px
      padding: 0.5em 0.75em 0.5em 2.5em
      margin-left: 0.4em
      line-height: 1em

    @media #{$medium-md-and-down}
      font-size: $h6-fontsize !important

  .ant-tag
    &.active
      background-color: #fccd67

    &.non-active
      background-color: #e1eaee

  .points
    font-size: 1em
    line-height: 1.4em
    display: inline-flex
    align-items: center
    gap: 5px

    &.points_write_off
      background-color: #f6e2e9
      color: $btn-background-primary-color

  .ant-pagination-options
    display: none

  .ant-table-thead
    th
      &:nth-child(3)
        text-align: right
        padding-right: 2.1em

  .ant-table-tbody
    .ant-table-cell
      &:nth-child(2)
        .ant-typography
          color: $text-secondary-color
          font-weight: $text-font-weight-normal

      &:nth-child(3)
        text-align: right

  .bold
    font-weight: $text-font-weight-strong !important
