$background-thead: #E4E3E1
$tag-background-color: #F9AB00
$time-availability-background-color-secondary: #a3beca

.reservation-overview
  .ant-table-thead
    th
      background: $background-thead !important
      color: $text-secondary-color !important
      font-size: 1em
      font-weight: $text-font-weight-strong !important

      &:before
        width: 0 !important

  .ant-table-tbody
    .ant-table-cell
      color: $text-secondary-color
      font-size: 1em

      &:nth-child(2)
        font-weight: $text-font-weight-strong

  .ant-tag
    font-size: 1em
    padding: 0.29em 0.87em
    border-radius: 1.25em
    border: none
    font-weight: $text-font-weight-strong
    color: $text-secondary-color

    &.aktivni
      background-color: $background-secondary-color-male
      //color: white

    &.odbaven
      background-color: $tag-background-color

    &.neuspesnyodber
      background-color: rgba(0,75,108,0.12)
      color: rgba(0,75,108,0.48)

    &.nedostaven
      background-color: rgba(185,14,73,0.12)
      color: rgba(0,75,108,0.48)

      .anticon
        color: #d97c9c

  .ant-list
    border-radius: 9px

    .ant-list-header
      background-color: $background-thead
      text-align: left
      padding-left: 12px
      color: $text-secondary-color
      font-weight: $text-font-weight-strong
      border-radius: 9px 9px 0 0
      -webkit-font-smoothing: antialiased

  .ant-list-items
    .ant-list-item
      background-color: $primary-color
      padding: 10px 12px 15px

      .ant-typography
        color: $text-secondary-color

        .ant-tag
          .anticon
            margin-right: .4em

      .ant-divider-vertical
        border-inline-start: 1px solid $text-secondary-color

      &:last-of-type
        border-radius: 0 0 9px 9px

  .list-tag-btn-wrapper
    width: 100%

    :nth-child(2)
      margin-left: auto

//Reservation Time Period
.time-availability-wrapper
  label.ant-form-item-no-colon
    height: $time-availability-height !important

    @media screen and (max-width: 575px)
      height: auto !important
      font-size: $text-helper-font-size !important

.time-availability-container
  display: flex
  flex-direction: row
  max-width: 289px

  .time-availability
    height: $time-availability-height
    margin: 0 5px
    max-width: 20px
    width: auto
    background-color: #f3f3f3
    display: flex
    flex-direction: column
    justify-content: flex-end
    border-radius: 4px

    &:first-child
      margin: 0 5px 0 0

    &:last-child
      margin: 0 0 0 5px

    .time-availability-top
      background-color: $time-availability-background-color-secondary
      width: 13px
      transition: height .3s ease-in-out
      border-top-left-radius: 2px
      border-top-right-radius: 2px

      @media screen and (max-width: 380px)
        width: 10px

    .time-availability-bottom
      color: $time-availability-background-color-secondary
      font-size: $text-helper-font-size
      line-height: 1em
      padding-top: 2px
      background-color: $primary-color

      @media screen and (max-width: 380px)
        font-size: 10px