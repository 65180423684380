.App
  text-align: center

.App-header 
  background-color: #fff
  min-height: 100vh
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  font-size: calc(10px + 2vmin)
  color: white

.ant-alert
  padding: 1em
  border-radius: 1em
  margin: 1em 0

  .ant-alert-message
    padding: 0 0.5em

  &.ant-alert-info
    border: 1px solid #1677ff33!important

    .ant-alert-message
      color: #0666cc

    .ant-alert-icon
      font-size: 2em
      color: #1677ffbb

  &.ant-alert-warning
    border: 1px solid #faad1433!important

    .ant-alert-message
      color: #d79508

    .ant-alert-icon
      font-size: 2em
      color: #faad14

.ant-message
  .ant-message-notice
    .ant-message-notice-content
      padding: 1em 2em
      font-weight: $text-font-weight-strong


.ant-btn-icon
  .ant-spin
    opacity: 0
    display: inline-block
    position: absolute
    top: 14px
    left: 12px
    transition: left 0.3s ease-out, opacity 0.3s ease-in-out

    &.ant-spin-spinning
      opacity: 1
      left: 24px

    .ant-spin-dot-item
      background-color: white


.reservation-confirm
  padding: 4em 0

  span
    color: $text-secondary-color
    font-size: 1.14em
    font-weight: $text-font-weight-normal

    &.highlighting
      font-weight: $text-font-weight-strong
      font-size: 1em

  .ant-image
    padding-bottom: 10px
    max-width: 200px

.ant-spin-nested-loading
  .ant-spin-blur::after
    border-radius: 6px

.logo
  display: block

  .on-hover-animate-symbol
    transform-origin: 47.8% 50%
    transition: transform .3s

  &:hover
    .on-hover-animate-symbol
      transform: rotate(60deg)