$background-wait-color: #9FBFCD
$background-active-color: #F9AB00

.ant-steps
  .ant-steps-item

    &.ant-steps-item-active
      .ant-steps-item-title
        color: rgba(0,75,108,0.60) !important

      .ant-steps-item-icon
        background-color: $background-active-color
        border: solid 2px $primary-color

    &.ant-steps-item-wait
      .ant-steps-item-icon
        background-color: $background-wait-color
        border: solid 2px $background-wait-color !important

        &:hover
          border: solid 2px $background-wait-color !important
          .anticon, .ant-steps-icon
            color: $primary-color !important

    &.ant-steps-item-finish
      .ant-steps-item-container
        &:hover
          .ant-steps-item-icon
            border-color: $text-secondary-color !important

      .ant-steps-item-icon
        .ant-steps-icon
          top: -0.5px !important

    .anticon
      color: $primary-color !important

    .ant-steps-item-title
      line-height: $line-height-h4 !important
      color: $text-secondary-color !important
      font-size: calc($default-font-size + 1px)
      font-weight: $text-font-weight-strong
      padding-inline-end: 0


    .ant-steps-item-description
      .mt-2
        margin-top: 1em

      .q26-subtitle
        color: $text-primary-color
        font-size: 1.15em
        font-weight: $text-font-weight-strong
        padding: 1em 0 0 0

      .ant-radio-wrapper
        color: $text-primary-color
        font-weight: $text-font-weight-strong

      .ant-radio-checked
        .ant-radio-inner
          background-color: $primary-color
          &:after
            background-color: $text-secondary-color
            transform: scale(0.65) !important

        &:after, &:hover
          border-color: rgba(0,75,108,0.48) !important
          border: 2px

      .ant-radio-inner
        border-color: rgba(0,75,108,0.48)

    .ant-steps-item-description:empty
      display: none

    .ant-steps-item-icon
      background-color: $text-secondary-color
      border-color: $text-secondary-color

      .anticon, .ant-steps-icon
        color: $primary-color !important

    .ant-steps-item-container
      >.ant-steps-item-tail
        top: 5px !important

      .ant-steps-item-icon
        .ant-steps-icon
          top: -1.5px

    .ant-steps-item-content
      background-color: #dde7eb
      border-radius: 12px
      padding: 12px 24px
      margin: 5px 0 5px 0

      .ant-steps-item-title
        color: $text-secondary-color

      label:not(.ant-radio-wrapper)
        margin: 1.2em 0 0.3em 0
        display: inline-block
        height: 1em!important
        line-height: 1em
        color: $text-secondary-color
        font-weight: $text-font-weight-strong

      .ant-radio-group
        width: 100%

      .textarea-container
        text-align: left

        textarea
          border: 1px solid rgba(0,0,0,0.18)
          color: $text-primary-color

          &:hover
            border-color: rgba(0,0,0,0.18) !important

          &:focus
            border: 1px solid $text-secondary-color !important
            box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0 0 8px rgba(0, 0, 0, 0.2)

@media #{$medium-md-and-down}
  .ant-steps-item
    text-align: center

  .ant-steps-item-title
    margin-top: .5em

  .ant-steps-item-container
    display: flex
    flex-direction: column
    align-items: center

    .ant-steps-item-tail
      left: 50% !important

    .ant-steps-item-icon
      margin-right: 0 !important
      z-index: 2

    .ant-steps-item-content
      margin: -15px 0 15px 0 !important
      width: 95%
      z-index: 1

.survey-page
  padding-bottom: 8.6em

  h1
    color: $text-secondary-color
    font-size: 2.25em
    font-weight: $text-font-weight-strong
    margin: 0 0 2.625rem 0

  .survey-text
    font-size: 1.15em
    color: $text-secondary-color
    margin: 0 3em 2em 3em
    text-align: left
    line-height: 1.5em

    @media #{$medium-md-and-down}
      margin: 0 1em 2em 1em
      text-align: center

    span
      font-weight: $text-font-weight-strong

  .ant-steps
    .ant-steps-item-content
      background-color: $primary-color

    @media #{$medium-md-and-down}
      margin: 0 1em 2em 1em
