.ant-popover
  .ant-popover-content
    .ant-popover-inner
      border-radius: 12px
      padding: 2.3em

      .ant-popconfirm-message
        text-align: center

        .ant-popconfirm-title, .ant-popconfirm-description
          color: $text-secondary-color

      .ant-popconfirm-buttons
        margin-top: 2em
        text-align: center

        .ant-btn
          height: 2rem
          border-radius: 32px
          padding: 8px 2em
          line-height: $line-height-default
          color: $text-primary-color
          background-color: $primary-color
          margin: 0 0.6em

          &:hover
            border-color: $popover-border-color

          &.ant-btn-primary
            background-color: $btn-background-primary-color
            color: $primary-color
            border-color: $btn-background-primary-color

            &:hover
              border-color: $btn-background-primary-color