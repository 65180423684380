.card
  border-radius: 18px
  width: 636px

  @media screen and (max-width: 993px)
    width: auto

  &.reservation
    .btn-primary
      width: 252px

  &.register
    .ant-card-body
      padding: 24px 0

    .btn-container
      .btn-primary
        width: 340px

      @media #{$small-and-down}
        .ant-space-item
          width: 100%

  h1
    margin-bottom: 24px !important
    font-size: $h2-fontsize

  p
    margin-bottom: 20px
    font-size: $text-font-size
    line-height: $line-height-h6

  a
    color: $text-primary-color
    text-decoration-line: underline
    font-weight: $text-font-weight-strong

    &:hover
      color: $text-primary-color
      text-decoration-line: underline

  .helper-text
    background: $background-primary-color
    font-size: $text-helper-font-size
    color: $text-secondary-color
    letter-spacing: 0
    line-height: calc($line-height-default - 1px)
    font-weight: $text-font-weight-normal
    padding: 13px 12px
    text-align: left
    border-radius: 8px
    margin-bottom: 0
    margin-left: 50.75px

    @media #{$small-and-down}
      margin-left: 0

  .ant-card-body
    padding: 36px 1em 66px

    @media #{$small-and-down}
      padding: 2em 1em

    .card-text
      color: $text-primary-color
      font-weight: $text-font-weight-normal
      margin-top: 0

    .ant-result
      .ant-result-title
        color: $text-primary-color