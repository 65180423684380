form
  border-radius: 18px

  .btn-container
    @media #{$medium-and-down}
      flex-direction: column

    @media #{$small-and-down}
      width: 100%
      padding: 0 1em

      .ant-space-item:nth-child(2n)
        width: 100%

  .ant-form-item
    margin-bottom: 12px

    .ant-form-item-explain-error
      margin: 2px auto 5px

  .form-container, .additional-info, .contact-address
    padding: 0 4.875em 0 1.25em

    @media #{$small-and-down}
      padding: 2em 1em

  .additional-info, .contact-address
    background: $form-collapsible-background-color
    margin-top: -15px
    padding-top: 25px
    padding-bottom: 15px

  .ant-divider
    margin-bottom: 0 !important

  label
    color: $text-secondary-color !important
    font-size: $text-helper-font-size !important
    font-weight: $text-font-weight-strong
    margin-right: 1em

    &:before
      content: "" !important

    &:after
      content: "" !important

    &.ant-form-item-required
      &:after
        content: "*" !important
        font-size: $text-helper-font-size
        color: $text-color-required-red
        font-weight: $text-font-weight-strong
        visibility: visible !important

  .required
    label
      &:after
        content: "*" !important
        font-size: $text-helper-font-size
        color: $text-color-required-red
        font-weight: $text-font-weight-strong
        visibility: visible !important

  input:not(#login_password):not(.ant-picker input):not(#national_id), .ant-input-affix-wrapper
    background: $primary-color
    border: 1px solid rgba(0,0,0,0.18)
    border-radius: 3px !important
    height: $input-height
    font-size: $default-font-size
    color: $text-primary-color
    line-height: $line-height-h4
    font-weight: $text-font-weight-normal
    padding: 12px 15px

    .anticon, .ant-input
      color: inherit !important

    &:hover
      border-color: rgba(0,0,0,0.18) !important

    &:focus
      border: 1px solid $text-secondary-color !important
      box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0 0 8px rgba(0, 0, 0, 0.2)

    &::placeholder
      color: rgba(0,0,0,0.36)

  .ant-input-affix-wrapper-focused
    border: 1px solid $text-secondary-color !important
    box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0 0 8px rgba(0, 0, 0, 0.2)

    &:hover
      border-color: $text-secondary-color !important

  &.ant-form-horizontal
    label
      margin-bottom: 0.75em

      @media screen and (max-width: 575px)
        font-size: $text-helper-font-size !important

  .disabled
    input
      color: rgba(0, 0, 0, 0.25) !important
      background-color: rgba(0, 0, 0, 0.04) !important
      border-color: $input-disabled-border-color !important

  //Select
  .ant-select
    height: $input-height !important

    &.ant-select-focused
      .ant-select-selector
        border-color: rgba(0,0,0,0.18) !important

      .ant-select-selection-item
        color: $text-primary-color

    &:hover
      .ant-select-selector
        border-color: rgba(0,0,0,0.18) !important

    &:focus
      .ant-select-selector
        border: 1px solid $text-secondary-color !important
        box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0 0 8px rgba(0, 0, 0, 0.2)

    .ant-select-selector
      height: $input-height !important
      border-radius: 3px !important

      .ant-select-selection-item, .ant-select-selection-placeholder
        line-height: $input-height !important
        text-align: left

    .ant-select-arrow
      svg
        width: $ant-select-arrow-svg-height-width
        height: $ant-select-arrow-svg-height-width
        color: $text-primary-color

  .ant-select-dropdown
    .ant-select-item-option-selected
      font-weight: 500


  .datepicker, .select-date, .flex-start
    .ant-col:last-child
      align-items: flex-start

  //Datepicker
  .ant-picker
    height: $input-height !important
    padding-right: 0
    border-radius: 3px !important

    &.ant-picker-focused
      border: 1px solid $text-secondary-color !important
      box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0 0 8px rgba(0, 0, 0, 0.2)

    &:hover
      border-color: rgba(0,0,0,0.18) !important

    &:focus
      border: 1px solid $text-secondary-color !important
      box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0 0 8px rgba(0, 0, 0, 0.2)

    .ant-picker-input
      height: 2.188rem !important

      input
        font-family: 'Rubik', sans-serif

      .ant-picker-suffix
        padding: 0 14.5px
        height: $input-height
        background: rgba(0,75,108,0.09)
        width: $input-height
        border-left: 1px solid #D1CFCF
        margin-left: 0
        justify-content: center

        svg
          height: $ant-picker-suffix-svg-height-width
          width: $ant-picker-suffix-svg-height-width
          color: $text-primary-color

      .ant-picker-clear
        right: 0
        width: calc(3rem - 1px)
        height: calc(3rem - 2px)
        background: $primary-color
        border-bottom-right-radius: 3px
        border-top-right-radius: 3px

        > span
          padding: 12.5px 0

          svg
            width: 1.333em
            height: 1.333em


  .select-date
    .ant-select-selection-item, .ant-select-selection-placeholder
      text-align: center !important

  //Radio
  .ant-radio-group
    .ant-radio-wrapper
      align-items: center

      span:last-child
        font-size: $text-font-size
        color: $text-primary-color
        line-height: $line-height-h6
        font-weight: $text-font-weight-strong

    .ant-radio-inner
      width: $ant-radio-inner-height-width
      height: $ant-radio-inner-height-width

  .contact-descnumber
    .ant-form-item-control-input-content
      height: $input-height

      > span
        margin: 14px 5px !important
        height: $input-height
        display: inline-block

  .red
    color: $text-color-required-red

  //Switch consents
  .consent:not(.consent ~ .consent)
    margin-top: 3em

  .consent
    .ant-form-item-control-input-content
      text-align: left
      display: flex

    @media #{$small-and-down}
      margin: 1.4em 1em

    .switch-container
      display: inline-block
      margin-bottom: 0

      .ant-form-item-control-input
        min-height: 1.375rem

    label
      color: $text-primary-color !important
      font-size: $text-font-size !important
      font-weight: $text-font-weight-normal
      line-height: $line-height-h5
      margin-right: 0.3em

    .ant-btn.ant-btn-link
      padding: 0
      line-height: $line-height-h6
      height: 1.125rem

      span
        color: $text-primary-color
        font-weight: $text-font-weight-strong
        text-decoration-line: underline

        &:hover
          color: $text-primary-color
          text-decoration-line: underline

    .ant-col:last-of-type
      margin: 0 auto

  .text-info
    font-size: $text-helper-font-size
    color: $text-secondary-color !important
    letter-spacing: 0
    text-align: center
    line-height: $line-height-default
    font-weight: $text-font-weight-normal

.empty-data
  text-align: center
  color: rgba(0, 0, 0, 0.25)

  svg
    height: $empty-data-svg-height-width
    width: $empty-data-svg-height-width

//Steps
.ant-steps
  .ant-steps-item-wait:not(.step-open-by-default),.ant-steps-item-finish:not(.step-open-by-default)
    >.ant-steps-item-container
      >.ant-steps-item-content
        z-index: 1

        >.ant-steps-item-description
          max-height: 0
          overflow: hidden
          padding-bottom: 0


  .ant-steps-item-process.ant-steps-item-active
    >.ant-steps-item-container
      >.ant-steps-item-content
        >.ant-steps-item-description
          overflow: visible
          transition: all 0.3s cubic-bezier(0.4, 0, 0.6, 1) !important

//Datepicker-dropdown
.ant-picker-dropdown
  .ant-picker-body
    .ant-picker-content
      .ant-picker-cell:not(.ant-picker-cell-in-view, .ant-picker-cell-disabled)
        color: #777777


