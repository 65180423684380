main
  padding: 3em
  transition: background-color 1s ease-in-out

  &.male
    background-color: $background-secondary-color-male

  &.female
    background-color: $background-secondary-color-female

  .left-col
    .ant-space-item:first-child
      .ant-image
        width: 19.6em

    .ant-space-item:not(:first-child)
      .ant-image
        width: 15em

  .left-col, .left-col-mobile
    h4
      color: $text-secondary-color
      font-size: $h4-fontsize

      span
        color: inherit
        font-weight: 500
        font-size: 1.6em

  .left-col-mobile
    margin-bottom: 3em

    .ant-image
      width: 60%

  .right-col
    //display: flex
    padding-right: 15px !important

    @media #{$medium-and-up}
      > .ant-row
        width: 26em

    .right-col-link
      .ant-space-item
        background-color: $btn-text-color
        width: 12em
        height: 12em
        padding: 1.5em 2em 2em
        margin: 0.5em
        border-radius: 2em

        a
          display: flex
          flex-direction: column
          align-items: center

          .ant-image
            width: 80px
            height: 65px

          h4
            margin: 1.5em 0 0 0
            font-size: 1.2em
            font-weight: 500

    .ant-row
      &:first-child
        @media #{$medium-and-down}
          justify-content: center

    .banner-row
      width: 100%
      text-align: left
      padding-left: 0.5em

      @media #{$medium-and-down}
        text-align: center

      .ant-image
        width: 25em

        @media #{$small-and-down}
          width: 100%

      @media #{$small-and-down}
        width: 90%
        padding-left: 0

      h5
        text-align: right
        color: $text-secondary-color
        margin-bottom: 2px
        font-size: 0.8em

  @media #{$small-xs-and-down}
    padding: 2em 0.5em

.advertising
  text-align: left

  p
    color: $text-secondary-color
    font-size: 0.688rem

  @media #{$medium-and-down}
    text-align: center
