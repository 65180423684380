$primary-color: #ffffff
$background-primary-color: #F3F2F0
$background-secondary-color-male: #d1e4ec
$background-secondary-color-female: #ffe2ec
$text-primary-color: #000000
$text-secondary-color: #004B6C

//Login
$background-color: #cbdee6
$recovery-password-color: #333
$dividing-line-color: #004364

//Button
$btn-text-color: $primary-color
$btn-text-hover-color: $text-primary-color
$btn-background-primary-color: #B90E49
$btn-background-secondary-color: $text-secondary-color
$btn-background-hover-color: #F9AB00
$btn-background-focus-color: $text-primary-color
$btn-cancel-background-focus-color: #D1CFCF
$btn-height: 3rem
$btn-radius: 3rem


//  Typography
$default-font-size: 1rem
$h1-fontsize: 2.25rem !default
$h2-fontsize: 1.875rem !default
$h3-fontsize: 1.5rem !default
$h4-fontsize: 1.225rem !default
$h5-fontsize: 1.125rem !default
$h6-fontsize: 1.1rem !default
$text-font-size: 0.875rem
$text-helper-font-size: 0.75rem

$line-height-default: 1rem
$line-height-h1: 2.625rem !default
$line-height-h2: 2.25rem !default
$line-height-h3: 1.875rem !default
$line-height-h4: 1.5rem !default
$line-height-h5: 1.375rem !default
$line-height-h6: 1.125rem !default

$text-font-weight-normal: 400
$text-font-weight-strong: 500

//  Form
$text-color-required-red: #B90E49
$input-height: $btn-height
$ant-select-arrow-svg-height-width: 1.063rem
$ant-picker-suffix-svg-height-width: 1.25rem
$ant-radio-inner-height-width: 1.125rem
$empty-data-svg-height-width: 4.688rem
$input-disabled-border-color: #d9d9d9
$form-collapsible-background-color: #e4e3e1
$time-availability-height: 3.75rem

//  Popover
$popover-border-color: #d9d9d9

//  Modal
$modal-close-width: 1.875rem

//  Media Query Ranges
$small-screen: 425px !default
$small-xs-screen: 500px !default
$small-sm-screen: 575px !default
$medium-md-screen: 767px !default
$medium-screen: 991px !default
$large-screen: 1199px !default


$large-and-down: "only screen and (max-width : #{$large-screen})" !default
$small-and-down: "only screen and (max-width : #{$small-screen})" !default
$small-xs-and-down: "only screen and (max-width : #{$small-xs-screen})" !default
$small-sm-down: "only screen and (max-width : #{$small-sm-screen})" !default
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default
$medium-md-and-down: "only screen and (max-width : #{$medium-md-screen})" !default
$small-height-down: "only screen and (max-height : #{$small-screen})" !default

$medium-and-up: "only screen and (min-width : #{$medium-screen})" !default
$medium-md-and-up: "only screen and (min-width : #{$medium-md-screen})" !default