.header
  z-index: 100
  padding: 12px 10px 14px
  background-color: $primary-color
  position: sticky
  top: 0

  .ant-row
    align-items: center

    .ant-space
      .ant-space-item:first-child
        margin-right: auto

      .ant-space-item:last-of-type
        margin-left: auto

      .logo
        height: 52px

  //Hamburger menu

  .hamburger-menu
    padding: 0

    .ant-image
      width: 2rem

.menu-drawer
  .ant-drawer-header-title
    flex-direction: row-reverse

    .ant-drawer-close
      margin-inline-end: 0

      .ant-image
        width: 1.875rem


    .ant-drawer-title
      text-align: center
      padding-left: 42px

      .ant-image
        width: 138px

  .ant-drawer-wrapper-body
    .ant-drawer-header
      padding: 20px 20px 16px 20px

      @media screen and (max-width: 600px)
        padding: 16px 10px

    .ant-drawer-body
      position: relative

      a
        display: inline-block
        margin: 5px 0

      .ant-btn
        text-align: left !important
        height: auto
        padding: 3px 15px

        span
          font-size: $h5-fontsize
          color: $text-primary-color
          line-height: $line-height-h4
          font-weight: $text-font-weight-strong

        &:hover
          color: inherit
          background-color: inherit

      .menu-moje-amber-image
        text-align: center

        .ant-image
          position: absolute
          left: 28.5%
          top: 75%
          width: 162px

        @media #{$small-height-down}
          display: none