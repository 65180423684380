.ant-table-wrapper .ant-table
  border-radius: 8px

.ant-table-thead
  th
    background: $background-thead !important
    color: $text-secondary-color !important
    font-size: 1em
    font-weight: $text-font-weight-strong !important

    &:before
      width: 0 !important

.ant-table-tbody
  .ant-table-cell
    color: $text-secondary-color
    font-size: 1em

    &:nth-child(2)
      font-weight: $text-font-weight-strong

.ant-tag
  font-size: 1em
  padding: 0.29em 0.87em
  border-radius: 1.25em
  border: none
  font-weight: $text-font-weight-strong
  color: $text-secondary-color

  &.aktivni
    background-color: $background-secondary-color-male
  //color: white

  &.odbaven
    background-color: $tag-background-color

  &.neuspesnyodber
    background-color: rgba(0,75,108,0.12)
    color: rgba(0,75,108,0.48)

  &.nedostaven
    background-color: rgba(185,14,73,0.12)
    color: rgba(0,75,108,0.48)

    .anticon
      color: #d97c9c

.ant-list
  border-radius: 9px

  .ant-list-header
    background-color: $background-thead
    text-align: left
    padding-left: 12px
    color: $text-secondary-color
    font-weight: $text-font-weight-strong
    border-radius: 9px 9px 0 0
    -webkit-font-smoothing: antialiased

.ant-list-items
  .ant-list-item
    background-color: $primary-color
    padding: 10px 12px 15px

    .ant-typography
      color: $text-secondary-color

      .ant-tag
        .anticon
          margin-right: .4em

    .ant-divider-vertical
      border-inline-start: 1px solid $text-secondary-color

    &:last-of-type
      border-radius: 0 0 9px 9px

.list-tag-btn-wrapper
  width: 100%

  :nth-child(2)
    margin-left: auto