.btn
  font-size: $default-font-size
  letter-spacing: 0
  text-align: center
  font-weight: $text-font-weight-strong
  min-height: 3rem
  height: 100%
  border: none
  border-radius: $btn-radius
  padding: 0.75em 2.5em
  color: $btn-text-color
  white-space: normal


  @media #{$small-and-down}
    width: 100% !important

  span
    line-height: 1.5em
    color: $btn-text-color

  &.btn-primary
    background-color: $btn-background-primary-color

  &.btn-secondary
    background-color: $btn-background-secondary-color

    &.btn-survey-annual
      @media #{$medium-and-up}
        max-width: 280px

      @media #{$small-sm-down}
        max-width: 280px

  &.btn-step
    height: 2.25rem
    padding: 0.3em 1.5em
    border: 2.25rem
    min-width: 150px
    max-width: 150px
    margin-top: 1em

  &:hover
    color: $btn-text-hover-color !important
    background-color: $btn-background-hover-color !important

    span
      color: $btn-text-hover-color

  &:focus
    background-color: $btn-text-hover-color !important
    color: $btn-text-color !important

    span
      color: $btn-text-color !important

  &:disabled
    background-color: grey
    opacity: 0.25

.btn-cancel
  font-size: $text-font-size
  line-height: 1.125em
  font-weight: $text-font-weight-strong
  padding: 0.875em 0.65em
  height: $btn-height

  .ant-btn-icon
    font-size: 1em
    font-weight: $text-font-weight-strong
    margin-top: 1px

    svg
      color: $btn-text-hover-color
      margin-top: 1px

  span:last-child
    text-decoration-line: underline

  &:hover
    background-color: $btn-background-hover-color !important
    border-radius: 3px

  &:focus
    background-color: $btn-cancel-background-focus-color !important


.btn-collapsible
  background: $btn-background-secondary-color
  font-size: calc($default-font-size - 3px)
  color: $btn-text-color
  text-align: center
  line-height: 0.9375rem
  font-weight: $text-font-weight-strong
  border-radius: 2em

  &:hover
    color: $btn-text-color !important
    background: $btn-background-secondary-color !important

  svg
    width: 0.75rem
    height: 0.75rem
