$footer-text-primary-color: #adcce1
$footer-background-primary-color: #1b4079
$background-gradient-color: linear-gradient(299deg, #b90e49 3%, #f1680b 18%, #f9ab00 33%, #f9ab00 67%, #f1680b 82%, #b90e49 97%)
$icon-phone-color: #fca91c
$white: #fff
$copyright-color: #7899b9
$font-weight-strong: 700
$social-icon-color: #000
$font-family: "Nunito Sans", Arial, sans-serif
$font-size: 1.0625rem
$font-size-small: 0.8125rem
$line-height-small: 0.903125rem

footer.ant-layout
  background-color: $footer-background-primary-color
  color: $footer-text-primary-color
  font-family: $font-family
  font-size: $font-size
  border-radius: 2.25rem 2.25rem 0 0
  position: relative
  z-index: 5

  .ant-row
    max-width: 1236px
    margin: 0 auto
    width: 100%

    @media #{$large-and-down}
      max-width: 960px

    @media #{$medium-and-down}
      max-width: 720px

    @media #{$medium-md-and-down}
      max-width: 540px

    &.first-row
      padding: 3.75rem 1.125rem

      @media #{$medium-md-and-down}
        padding: 1.5rem 1.125rem 0.8rem

        > .ant-col
          padding: 0 0.75rem

      .footer-col-first
        padding: 0 2.25rem 0 0

        @media #{$large-and-down}
          padding: 0 0 1.2rem 0

        @media #{$medium-and-down}
          padding: 0

        @media #{$medium-md-and-down}
          padding-bottom: 1.2rem

      .footer-col-second
        padding: 0 1.8125rem 0 1.7625rem

        @media #{$large-and-down}
          padding: 0 1.8125rem 1.2rem 0

        @media #{$medium-and-down}
          padding: 0 1.3rem 1.2rem 1.6rem

        @media #{$medium-md-and-down}
          padding: 0 1.5rem 1.2rem 1rem

        @media #{$small-sm-down}
          padding-left: 0

      .footer-col-three
        padding: 0 2.25rem 0 1.425rem

        @media #{$large-and-down}
          padding-left: 0

        @media #{$medium-and-down}
          padding: 0 1rem 0 1.05rem

        @media #{$medium-md-and-down}
          padding: 0 2.25rem 1.2rem 0

        .footer-col-three-second
          padding: 0 0 0 1.6875rem

          @media #{$large-and-down}
            padding-left: 1.2rem

          @media #{$medium-and-down}
            padding: 0 0 0 1.5rem

          @media #{$medium-md-and-down}
            padding: 0 0 0 2.125rem

          @media #{$small-sm-down}
            padding: 0

      .footer-col-four
        padding: 0 0 0 3.625rem

        @media #{$medium-and-down}
          padding: 0

        .footer-h3
          margin: 0 0.75rem 1.5rem

        .anticon-phone
          color: $icon-phone-color
          font-size: 1.58rem

        .footer-contact
          gap: 0 !important

        .btn-reservation
          color: $white
          background: $background-gradient-color
          background-size: 300% 100%
          display: inline-block
          border-radius: 1.875rem
          font-size: $font-size
          font-weight: $font-weight-strong
          line-height: 1.5rem
          min-height: 3rem
          padding: 0.75rem 2rem
          transition: all 0.3s, background 0.3s ease-in-out
          will-change: padding, background, color
          border: none
          font-family: $font-family

          @media #{$small-sm-down}
            width: calc(50% - 0.95rem)

          &:hover
            color: $white !important
            background-position: 100% 0

      .ant-col
        text-align: left

      .footer-text
        margin: 1.25rem 0.75rem
        display: block
        font-weight: 400
        font-size: $font-size-small
        line-height: $line-height-small
        color: $footer-text-primary-color
        font-family: $font-family

        a
          text-decoration: underline
          font-weight: $font-weight-strong
          font-size: $font-size-small
          line-height: $line-height-small
          color: $white
          font-family: $font-family

          &:hover
            text-decoration: none

      .footer-item
        font-size: $font-size
        line-height: 1.4875rem
        margin: 0 0 0.75rem
        font-weight: normal
        text-align: left
        font-family: $font-family

        @media #{$medium-md-and-down}
          margin: 0 0 0.76rem

        &:last-child
          margin-bottom: 0.796875rem

        a
          color: $white
          text-decoration-line: none
          font-size: $font-size
          line-height: 1.4875rem
          cursor: pointer
          font-family: $font-family

          &:hover
            text-decoration: underline
            color: $white

      .footer-h3
        display: block
        font-size: $font-size
        font-weight: $font-weight-strong
        margin: 0 0 1.5rem 0
        line-height: 1.4875rem
        color: $footer-text-primary-color
        font-family: $font-family

      a.footer-h3:hover
        text-decoration: underline

      .footer-tel
        align-items: center
        background: $white
        color: $footer-background-primary-color
        border-radius: 0.75rem
        display: flex
        flex-wrap: wrap
        font-size: $font-size
        gap: 1.13rem
        padding: 1.125rem
        text-decoration: none
        transition: transform 0.3s

        svg
          height: 1.5rem
          width: 1.5rem

        strong
          font-weight: $font-weight-strong
          font-size: 1em
          line-height: 1.4875rem
          font-family: $font-family

        span
          font-size: $font-size-small
          line-height: $line-height-small
          font-family: $font-family

        &:hover
          transform: scale(1.05)

    &.second-row
      align-items: center
      padding: 2.5rem 1.125rem

      @media #{$large-and-down}
        padding: 1.7rem 1.125rem 2.5rem

      @media #{$medium-md-and-down}
        flex-direction: column-reverse
        gap: 1.125rem!important

      .footer-col-logo
        text-align: center

        .ant-space
          gap: .903125rem!important

          @media #{$medium-md-and-down}
            gap: .9375rem!important

        .footer-copyright
          color: $copyright-color
          font-size: $font-size-small
          line-height: $line-height-small

        .ant-typography
          font-family: $font-family

        .logo
          height: 3.125rem !important

          svg
            height: 3.125rem !important
            width: auto

      .footer-social-link
        text-align: right

        @media #{$medium-md-and-down}
          text-align: center
          padding: 0 0 1rem
          margin: 0.375rem 0

        .ant-space
          gap: 1.125rem!important

        .social-link
          background: $white
          border-radius: 50%
          color: $social-icon-color
          display: flex
          justify-content: center
          align-items: center
          margin: 0
          font-size: $font-size
          padding: 0.75rem
          transition: all .3s

          &:hover
            transform: scale(1.1)
            color: $social-icon-color

          svg
            width: 1.5625rem
            height: 1.5625rem

          &.instagram svg
            width: 1.625rem
            height: 1.625rem