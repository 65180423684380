.list-item-health
  color: $text-secondary-color
  height: 4em

  .ant-image
    width: 4em
    max-width: 25%

    img
      width: 2.25em

  div
    display: inline-block
    text-align: left
    font-size: 1.2em


  .list-item-title
    height: 2.2em
    width: 20em
    max-width: 40%
    text-align: left


  .list-item-value
    font-weight: 500
    height: 2.2em
    width: 5em
    max-width: 35%

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(4)
    .list-item-title, .list-item-value
      border-bottom: solid 1px rgba(0,0,0,0.15)

  @media #{$medium-md-and-down}
    &:nth-child(3)
      .list-item-title, .list-item-value
        border-bottom: solid 1px rgba(0,0,0,0.15)


/* Chart of monthly samples */

.monthly-samples-wrapper
  text-align: center
  display: grid
  justify-content: center
  justify-items: center
  grid-auto-flow: column

  .monthly-samples-chart-wrapper
    overflow: hidden
    display: inline-block
    background-color: rgba(0,0,0,0.06)
    border-radius: .75em
    width: 100%
    direction: rtl
    overflow-x: scroll

  .monthly-samples-label-area
    margin-top: 1px
    width: 115px

    .monthly-samples-label
      height: 55px
      line-height: 55px
      padding: 0 2em
      margin: 0
      text-align: left
      color: $text-secondary-color

      &:not(:last-of-type)
        border-bottom: solid 2px rgba(255,255,255,0.4)


/* OVERALL SAMPLES TABLE */

.samples-chart-table-wrapper
  line-height: 0
  white-space: nowrap
  vertical-align: middle
  color: $text-secondary-color

  .samples-chart-table
    font-size: 0.85em
    display: inline-block
    background-color: #ffffff33
    max-width: 730px
    width: calc( 100% - 115px )
    text-align: right
    border-radius: .75em
    overflow: scroll
    //max-width: calc(100% - 115px)
    direction: rtl



    .chart-table-col
      text-align: center
      direction: ltr
      background-color: $primary-color

      .chart-table-row
        line-height: 42px
        padding: 0 0.66em

        &:not(:last-of-type)
          border-bottom: solid 2px #ffe2ec

        &.row-date
          padding: 8px 0
          line-height: 14px
          font-size: 0.9em

          .row-year
            font-size: 0.9em
            opacity: 0.5

  .chart-table-header
    display: inline-block
    line-height: 42px
    vertical-align: top
    width: 115px


    .chart-table-header-item
      font-weight: 500
      text-align: left
      padding: 0 2em
      vertical-align: baseline


      &:not(:last-of-type)
        border-bottom: solid 2px rgba(255,255,255,0.4)

.donation-col
  .donation-header
    border: 1px solid $primary-color
    border-radius: 1em 1em 0 0
    padding: 1.5em 1em
    margin: 0 1em

    h3, p
      margin: 0
      color: $text-secondary-color

    h3
      font-weight: 500
      font-size: 1.5em
      line-height: 1.6

    p
      font-size: 1.2em

  .donation-thanks-text
    margin: 0 1em
    padding: 1.5em 0 2em 0
    background-color: $primary-color
    border-radius: 0 0 1em 1em
    color: $text-secondary-color

    .ant-col:nth-of-type(2n)
      border-left: 2px solid #d1e4ec
      border-right: 2px solid #d1e4ec

    p
      margin: 0
      font-size: 0.9em

    .donation-container-image
      padding: .3em 0
      font-size: 1.75em
      font-weight: 500
      display: flex
      align-items: center
      justify-content: center

      .ant-image
        margin-left: .5em

    .dontaion-text-bold
      font-weight: 500

    @media #{$large-and-down}
      padding: 0

      .ant-col
        padding: 1.5em 0

        &:nth-of-type(2n)
          border-left: none
          border-right: none
          border-top: 2px solid #d1e4ec
          border-bottom: 2px solid #d1e4ec