.login-page
  background-color: $background-color
  padding: 20px 20px 0px

  .recovery-password
    color: $recovery-password-color
    font-weight: 500
    width: 100%
    padding-left: 0
    text-align: left
    cursor: default

    svg
      margin-right: 8px

    a
      font-size: 0.875em
      text-decoration-line: underline

    &:hover
      color: $recovery-password-color !important

    span:last-child
      text-decoration-line: underline

  .password
    margin-bottom: 10px !important

  .dividing-line
    max-width: 540px
    color: $dividing-line-color

    .ant-divider
      &:before, &:after
        background-color:  rgba(0,0,0,0.18)
        transform: none

  .dividing-line::before, .dividing-line::after
    content: ''
    flex: 1 1 auto
    border-bottom: solid 1px $dividing-line-color

  .new-account
    padding-bottom: 60px

    .new-account-text
      color: $text-secondary-color
      font-size: $text-font-size
      line-height: $line-height-h6
      font-weight: $text-font-weight-normal

  .hand-drop-image
    .ant-image
      width: 189px